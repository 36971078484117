import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticationStatus } from "../app/features/global/globalSlice";
import CreateBlog from "../components/CreateBlog";
import PageNotFound from "./PageNotFound";
import AnalyticsTeasers from "../components/analytics/teasers/AnalyticsTeasers";

function Analytics(){
    let dispatch = useDispatch();
    let baseUrl = useSelector(state=>state.global.value.baseUrl);
    let isAuthenticated = useSelector(state=>state.global.value.isAuthenticated);

    useEffect(()=>{

    }, [])

    return(
        <div className="analyticsPage">
            {
                isAuthenticated==undefined ? 
                <PageNotFound /> :
                isAuthenticated ?
                <AnalyticsTeasers /> :
                <PageNotFound />
            }
        </div>
    )
}

export default Analytics;
import DashboardDrawer from "./DashboardDrawer";

export default function DesktopLayout(props){
    return(
        <div className="desktopLayout">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-4 leftt">
                        <DashboardDrawer />
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-8 right dashboardContent">
                        {props.comp}
                    </div>
                </div>
            </div>
        </div>
    )
}
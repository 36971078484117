import React from "react";
import { Link } from "react-router-dom";
import DynamicHead from "../components/DynamicHead";

function PageNotFound(){
    return(
        <>
            <DynamicHead title="404 | Page Not Found" />
            <div className="pagenotfound" style={{backgroundImage: `url(/assets/images/space.jpg)`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="">
                                <h1>404</h1>
                                <h2>Page not found!</h2>
                                <p>Uh, No! we can't seem to find the page you're looking for. Try going back to the previous page.</p>
                                <Link to="/"><button className="mainButLight">Back to Home</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;
import axios from "axios";
import { useEffect, useState } from "react"
import config from "../../../../config/config";

export default function BlogsAnalytics(){

    let [blogs, setBlogs] = useState([]);
    let [blogAnayltics, setBlogAnayltics] = useState([]);

    useEffect(()=>{
        axios.get(`${config.baseUrl}/blog/view/analytics/weekly`)
        .then(response=>{
            if(response.status==200){
                setBlogs(response.data.data);
            }
        })
        .catch(e=>{
            console.log(e)
        })
    }, [])

    useEffect(()=>{
        reWriteData(blogs)
    }, [blogs])

    async function reWriteData(blogData){
        
        let countries =  [];
        let cViews =  [];
        let data = []
        await blogData.forEach((b)=>{
            if(!countries.includes(b.country)){
                countries.push(b.country);
                cViews.push(1);
            }
            else{
                countries.forEach((countr, index)=>{
                    if(countr==b.country){
                        cViews[index] = parseInt(cViews[index])+1
                    }
                })
            }
        })
        await countries.forEach((cc, index)=>{
            data.push({
                country: cc,
                views: cViews[index]
            })
        })
        console.log(blogAnayltics)
        await setBlogAnayltics(data)
    }

    return(
        <div className="blogsAnalytics singleAnalyticsTeaser">
            <div className="teaser-card">
                <div className="header">
                    <h6>View on Blogs this week</h6>
                </div>
                <div className="body">
                    <h2>{blogs.length}</h2>
                    <h6>Top Countries</h6>
                    <div className="separator"></div>
                    <div className="countries-views">
                        {
                            blogAnayltics.map(dd=>{
                                return(
                                    <div className="row">
                                        <div className="col-6">
                                            <p>{dd.country}</p>
                                        </div>
                                        <div className="col-6" style={{textAlign: 'right'}}>
                                            <p>{dd.views}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import Login from './pages/Login';
import CreateBlogPage from './pages/CreateBlogPage';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux'
import PageNotFound from './pages/PageNotFound';
import { setAuthenticationStatus } from './app/features/global/globalSlice';
import { useEffect, useState } from 'react';
import Dashboard from './pages/Dashboard';
import Cookies from 'js-cookie';
import UserMessages from './pages/UserMesages';
import SingleMessage from './pages/SingleMessage';
import DashboardDrawer from './components/DashboardDrawer';
import DashboardNav from './components/DashboardNav';
import Test from './pages/Test';
import DesktopLayout from './components/DesktopLayout';
import Analytics from './pages/Analytics';
// import 'react-toastify/dist/ReactToastify.css';

function App() {
  let dispatch = useDispatch();
  let persistor = persistStore(store);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  useEffect(()=>{
    if(Cookies.get('userCookie')==undefined){
      dispatch(setAuthenticationStatus(false));
    }
  }, [])

  return (
        <div className="dashboard">
          <Toaster />
          <Router>
            <Routes>
              <Route  path="/haha"  element={<DesktopLayout comp={<Test />} />} />
            </Routes>            
            {
              isPortrait ? 
              <>
                <Routes>
                  <Route  path="/"  element={<Login />} />
                  <Route  path="/dashboard"  element={<Dashboard />} />
                  <Route  path="/messages"  element={<UserMessages />} />
                  <Route  path="/analytics"  element={<DesktopLayout comp={<Analytics />} />} />
                  <Route  path="/message/:id"  element={<SingleMessage />} />
                  <Route  path="/create-blog"  element={<CreateBlogPage />} />
                  <Route  path="*"  element={<PageNotFound />} />
                </Routes>
              </>
              :
              <Routes>                
                <Route  path="/"  element={<Login />} />
                <Route  path="/dashboard"  element={<DesktopLayout comp={<Dashboard />} />} />
                <Route  path="/messages"  element={<DesktopLayout comp={<UserMessages />} />} />
                <Route  path="/analytics"  element={<DesktopLayout comp={<Analytics />} />} />
                <Route  path="/message/:id"  element={<DesktopLayout comp={<SingleMessage />} />} />
                <Route  path="/create-blog"  element={<DesktopLayout comp={<CreateBlogPage />} />} />
                <Route  path="*"  element={<PageNotFound />} />
              </Routes>
            }
          </Router>
        </div>
  );
}

export default App;

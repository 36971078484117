import Typewriter from 'typewriter-effect'

function Loader(props){
    return(
        <div className="loader">
            <div className="inner">
                <img src="/assets/images/circle.gif" alt="loader" />
                {/* <h2>Uploading Blog ...</h2> */}
                <h2>
                    <Typewriter
                        options={{
                            strings: props.message,
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </h2>
            </div>
        </div>
    )
}

export default Loader;
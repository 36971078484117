import axios from "axios";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import config from "../config/config";
import DashboardDrawer from "./DashboardDrawer";

export default function AllMessages(){

    let [messages, setMessages] = useState([]);

    useEffect(()=>{
        axios.get(`${config.baseUrl}/message/view/all`)
        .then(response=>{
            console.log(response);
            setMessages(response.data.data.reverse())
        })
        .catch(err=>{
            console.log(err)
        })
    }, [])

    return(
        <div className="allMessages">
            <div className="container-fluid">
                <div className="row">
                    {
                        messages?.reverse()?.map((msg)=>{
                            return(
                                <div className="col-lg-12 col-md-12">
                                    <div className="singleMessage">
                                        <h3 className="mainSectionHeading">Message from {msg.name.split(" ")[0]}</h3>
                                        <p>{msg?.message.substring(0,100)} ...</p>
                                        <span className="readMoreBut"><Link to={`/message/${msg._id}`} state={ msg }>Read Message</Link></span>                                       
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
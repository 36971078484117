import { useEffect } from "react";
import MessagesAnalytics from "../MessagesAnalytics";
import BlogsAnalytics from "./weekly/BlogsAnalytics";
import BlogsAnalyticsCities from "./weekly/BlogsAnalyticsCities";

function AnalyticsTeasers(){

    useEffect(()=>{
    }, [])

    return(
        <div className="analyticsTeasers">
            <div className="container">
                <div className="headings">
                    <h1 className="mainSectionHeading">Teasers</h1>
                    <h1 className="subSectionHeading">Analytics Teasers</h1>
                </div>
                <div className="separator">

                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <BlogsAnalytics />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <BlogsAnalyticsCities />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsTeasers;
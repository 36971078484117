import { useEffect } from "react"

export default function Test(props){

    useEffect(()=>{
        alert("Asdasd")
        console.log(props)
    }, [])


    return(
        <>
            <h1>sdfdsf</h1>
            {props.comp}
        </>
    )
}
import React, { useEffect, useState, useRef } from "react";
import {Helmet} from "react-helmet";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SingleBlog from "./singleBlog";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import Loader from "./Loader";
import config from "../config/config";
import DashboardNav from "./DashboardNav";
import DynamicHead from "./DynamicHead";

function CreateBlog(){

    let [title, setTitle] = useState('');
    let [description, setDescription] = useState('');
    let [keywords, setKeywords] = useState('');
    let [blogBody, setBlogBody] = useState('');
    let [img, setImg] = useState(null);
    let [imgSrc, setImgSrc] = useState();
    let [loading, setLoading] = useState(false)
    const inputRef = useRef();

    useEffect(()=>{
        console.log(imgSrc)
    }, [imgSrc]);

    async function createBlog(){
        if(img!=null && title.length>0 && blogBody.length>0 && description.length>0){
            let formData = new FormData()
            formData.append('title', title);
            formData.append('body', blogBody);
            formData.append('description', description);
            formData.append('keywords', keywords);
            formData.append('image', img);
            setLoading(true);

            axios.post(`${config.baseUrl}/blog/create`, formData)
            .then(response=>{
                console.log(response)
                if(response.data.status=='success'){
                    setTitle("");
                    setBlogBody("");
                    setDescription("");
                    setImg(null);
                    setImgSrc(null);
                    setKeywords("");
                    setLoading(false);
                    toast.success(`Blog saved successfully!`, { duration: 4000, position: 'bottom-center' });
                }
                else{

                }
            })
            .catch(e=>{
                console.log(e)
                setLoading(false);
                toast.error(`Something went wrong!`, { duration: 4000, position: 'bottom-center' });
            })
        }
        else{
            setLoading(false);
            toast.error(`Please fill all fields`, { duration: 4000, position: 'bottom-center' });
        }
    }

    return(
        <>
            <DynamicHead title="Create Blog" />
            <DashboardNav />
            {
                loading ? 
                <Loader message={['Loading ...', 'Creating your blog ...', 'Uploading image ...', 'Finalizing ...']} /> :
                <div className="createBlog">
                    <div className="container">
                        <h1 className="subSectionHeading">Create Blog</h1>
                        <hr />
                        <div className="row">
                            <div className="header">
                                <div className="imgUpload">
                                    <input type="file" name="" ref={inputRef} id="" onChange={(e)=>{setImg(e.target.files[0]); console.log(e.target.files[0]); setImgSrc(URL.createObjectURL(e.target.files[0])); console.log((URL.createObjectURL(e.target.files[0]))) }} />
                                    <div className="customImgUpload">
                                        <button onClick={()=>{inputRef.current.click()}}><i className="fas fa-upload"></i> UPLOAD HEADER IMAGE</button>
                                        <p>Recommended image size: (1200x630)</p>
                                        {
                                            imgSrc!=null && imgSrc.length>0 &&
                                            <div className="imgPreview">
                                                <img src={imgSrc} alt="" />
                                                <div className="deleteBut" onClick={()=>{setImg(null); setImgSrc(null)}}>
                                                    <i className="fas fa-times"></i>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="title">
                                    <input type="text" placeholder="Title" onChange={(e)=>setTitle(e.target.value)} value={title} />
                                    <input type="text" className="desc" placeholder="Short Description" onChange={(e)=>setDescription(e.target.value)} value={description} />
                                </div>
                            </div>
                            <div className="quillBody">
                                <ReactQuill theme="snow" style={{minHeight: '300px'}} value={blogBody} onChange={setBlogBody} />
                            </div>
                            <div className="footer">
                                <div className="keywords">
                                    <input type="text" placeholder="keywords" onChange={(e)=>setKeywords(e.target.value)} value={keywords} />
                                </div>
                                <button className="mainButDark" onClick={()=>createBlog()}>Save Blog</button>
                            </div>
                            <div className="separator"></div>
                            <div className="blogPreview">
                                <h4 className="mainSectionHeading">Blog Preview</h4>
                                <hr />
                                <SingleBlog title={title} img={imgSrc} body={blogBody} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CreateBlog;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthenticationStatus } from "../app/features/global/globalSlice";
import DashboardContent from "../components/DashboardContent";
import DashboardDrawer from "../components/DashboardDrawer";
import DashboardNav from "../components/DashboardNav";
import DynamicHead from "../components/DynamicHead";
import Loader from "../components/Loader";
import PageNotFound from "./PageNotFound";

function Dashboard(){
    
    let navigate = useNavigate()
    let dispatch = useDispatch();
    let authenticated = useSelector(state=>state.global.value.isAuthenticated)
    let [isAuthenticated, setIsAuthenticated] = useState(false);
    let [loading, setLoading] = useState(true); 
    let [drawer, setDrawer] = useState(false)

    useEffect(()=>{
        if(authenticated===true){
            setIsAuthenticated(true)
            console.log(loading)
            setLoading(false);
        }
        else{
            navigate("/")
            dispatch(setAuthenticationStatus(false))
        }
        console.log(loading)
    }, [])

    return(
        <>
            {
                !isAuthenticated ? <PageNotFound /> :
                <>
                    <DynamicHead title="Dashboard - Digital Ascendent" />                    
                    <DashboardNav />
                    <div className="dashboard">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 rightt">
                                    <DashboardContent />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Dashboard;
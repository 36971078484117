import DashboardNav from "../components/DashboardNav";
import DynamicHead from "../components/DynamicHead";
import AllMessages from "../components/AllMessages";

export default function UserMessages(){
    return(
        <>
            <DynamicHead title="Dashboard - Digital Ascendent" />
            <DashboardNav />
            <div className="userMessages">
                <div className="container-fluid">
                    <div className="headings">
                        <h2 className="mainSectionHeading">Messages</h2>
                        <h1 className="subSectionHeading">User Messages</h1>
                    </div>
                    <div className="separator"></div>
                    <div className="userMessagesContainer">
                        <AllMessages />
                    </div>
                </div>
            </div>
        </>
    )
}
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function SignoutBut(){

    let navigate = useNavigate()

    async function signout(){

        Cookies.remove("userCookie");
        await navigate("/");
    }

    return(
        <button className="navLogoutBut" onClick={()=>{signout()}}>
            <i className="fas fa-sign-out-alt"></i>
            Sign Out
        </button>
    )
}
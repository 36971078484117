import { Link } from "react-router-dom";

export default function DashboardDrawerItem(props){
    return(
        <div className="dashboardDrawerItems">
            <h5>
                <Link to={props.link}>
                    <i className={props.icon}></i>
                    {props.name}
                </Link>
            </h5>

        </div>
    )
}
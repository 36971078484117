const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    value: {
        isAuthenticated: false,
        baseUrl: 'http://localhost:8000/api',
    }
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setAuthenticationStatus: (state, action) =>{
            console.log(state.value)
            console.log(state)
            console.log(typeof state.value)
            state.value = {
                isAuthenticated: action.payload
            }
            // if('isAuthenticated' in state.value){
            //     state.value.isAuthenticated = action.payload
            //     console.log(state.value)
            // }
            // else{
            //     console.log("Unabled to set isAuthenticated")
            // }
        }
    }
})

export const { setAuthenticationStatus } = globalSlice.actions

export default globalSlice.reducer;
import axios from 'axios';
import { useEffect, useState } from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import DashboardNav from "../components/DashboardNav"
import DynamicHead from "../components/DynamicHead"
import config from '../config/config';
import PageNotFound from './PageNotFound';

export default function SingleMessage(){

    const location = useLocation();
    let { id } = useParams();
    let [msg, setMsg] = useState([]);

    useEffect(()=>{
        setMsg(location.state)
        console.log(location.state)
        if(location.state==null){
            axios.get(`${config.baseUrl}/message/view/${id}`)
            .then((response)=>{
                if(response.data.success==true){
                    if(response.data.data.length>0){
                        setMsg(response.data.data[0]);
                    }
                }
            })
            .catch(err=>{
                console.log(err);
            })
        }
    }, [])

    return(
        <>
            <DynamicHead title={`Message from ${msg?.name} - Digital Ascendent`} />
            <DashboardNav />
            <div className="singleMessagePage">
                <div className="container-fluid">
                    <div className="headings">
                        <h2 className='mainSectionHeading'>Message from {msg?.name}</h2>
                        <div className="separator"></div>
                    </div>
                    <div className="content">
                        <h5 className='info'>
                            <i className="fas fa-envelope"></i><a href={`mailto:${msg?.email}`}>{msg?.email}</a>
                            {msg?.phone?.length>0 && '- <i className="fas fa-phone-alt"></i>'} {msg?.phone}
                        </h5>
                        <hr />
                        <p>{msg?.message}</p>
                        <button>
                            <i className="fas fa-envelope"></i><a href={`mailto:${msg?.email}`}> Reply to message</a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";

export default function DynamicHead(props){
    const [offset, setOffset] = useState(0);
    
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(()=>{
        if(parseInt(offset)>200){

        }
    }, [offset])

    return(
        <Helmet>

            {/* search console verification  */}
            <meta name="google-site-verification" content="UJdIQoJC5HpqspjeTRzwCzCV_5YYX7iGmgeDqWMmA3Y" />
            {/* search console verification  */}

            <link rel="icon" href="/assets/icons/favicon.png"/>
            <title>{ props.title && props.title }</title>
            <meta name="description" content={ props.description && props.description} />
            <meta name="keywords" content={ props.keywords && props.keywords } />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="Digital Ascendent"/>
            {
                offset>200 ? <meta name="theme-color" content="#0c6a89" /> :
                <meta name="theme-color" content="#1b1b1b" />
            }

            {/* // Google */}
            <meta itemprop="name" content={props.title} />
            <meta itemprop="description" content={props.description} />
            <meta itemprop="image" content={props.img && props.img} />
            
            {/* // Facebook  */}
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image" content={props.img && props.img} />
            <meta property="og:url" content="https://www.digitalascendent.com/" />
            <meta property="og:site_name" content="Digital Ascendent" />
            <meta property="og:type" content="website" />
            
            {/* // Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:image" content={props.img && props.img} />
            <meta name="twitter:site" content="@digitalascendent" />
            <meta name="twitter:creator" content="@digitalascendent" />

            {/* React Slick  */}
            <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            {/* React Slick  */}

            {/* <!-- Bootstrap 5.1 CSS CDN  --> */}
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossOrigin="anonymous" />

            {/* <!-- font awesome CDN  --> */}
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.3/css/all.css" integrity="sha384-SZXxX4whJ79/gErwcOYf+zWLeJdY/qpuqC4cAa9rOGUstPomtqpuNWT9wdPEn2fk" crossOrigin="anonymous" />

            {/* <!-- React-Slick CDN  --> */}
            <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

            
            {/* font awesome CDN */}
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.3/css/all.css" integrity="sha384-SZXxX4whJ79/gErwcOYf+zWLeJdY/qpuqC4cAa9rOGUstPomtqpuNWT9wdPEn2fk" crossOrigin="anonymous" />


            {/* <!-- AOS CSS CDN   --> */}
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css" />

            {/* <!-- AOS Script  --> */}
            <script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>

            
            {/* <!-- Bootstrap 5.1 js CDN  --> */}
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossOrigin="anonymous"></script>
        </Helmet>
    )
}
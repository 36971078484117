import { toast } from "react-hot-toast";


const helpers = {
    notifyToast: function(status, message){
        status == 'success' ? 
        toast.success(message, { duration: 4000, position: 'botttom-right' }) :
        toast.error(message, { duration: 4000, position: 'botttom-right' })
    }
}

export default helpers;
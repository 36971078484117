import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import items from "../tools/NavContent";
import SignoutBut from "./buttons/SignoutBut";

function DashboardNav(){
 
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    if(isPortrait){
        return(
            <nav className="navbar dashboardNav navbar-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/dashboard">                    
                        <img src="/assets/images/white-logo-long.png" alt="digital-ascendent-logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        {
                            items?.map(i=>{
                                return(
                                    <li className="nav-item">
                                        <Link to={i.link} className="nav-link active" aria-current="page"><i className={i.icon}></i> {i.name}</Link>
                                    </li>
                                )
                            })
                        }
                        <SignoutBut />
                    </ul>
                    </div>
                </div>
            </nav>
        )
    }

}

export default DashboardNav;
import items from "../tools/NavContent";
import SignoutBut from "./buttons/SignoutBut";
import DashboardDrawerItem from "./DashboardDrawerItem";

function DashboardDrawer(){
    return(
        <div className="dashboardDrawer">
            <div className="header">
                <img src="/assets/images/white-logo-long.png" alt="digital-ascendent-logo" />
            </div>
            <div className="content">
                {
                    items?.map((i)=>{
                        return(
                            <DashboardDrawerItem icon={i.icon!=undefined ? i.icon : "fas fa-external-link-alt"} name={i.name} link={i.link} button={i.button} type={i.type} className={i.class} />
                        )
                    })
                }
                <hr />
                <SignoutBut />
            </div>
        </div>
    )
}

export default DashboardDrawer;

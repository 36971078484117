import React from "react";

function SingleBlog(props){
    return(
        <div className="singleBlog">
            <div className="header">
                <img src={props.img && props.img} alt="" />
                <h1>{props.title && props.title}</h1>
            </div>
            <div className="body">
                <div dangerouslySetInnerHTML={{__html: props.body.toString()}}>
                </div>
            </div>
            <div className="author">
                <hr />
                <h5>Written by</h5>
                <div>
                    <img src="/assets/images/tag.png" alt="company tag" />
                </div>
            </div>
        </div>
    )
}

export default SingleBlog;
import React, { useState, useContext } from "react";
import Cookies from 'js-cookie';
import axios from "axios";
import Typewriter from 'typewriter-effect';
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticationStatus } from "../app/features/global/globalSlice";
import config from "../config/config";
import helpers from "../tools/utilities";
import DynamicHead from "../components/DynamicHead";

function Login(){
    let dispatch = useDispatch()
    var [email, setEmail] = useState('');
    var [password, setPassword] = useState('');
    var [warning, setWarning] = useState('');
    var [passwordType, setPasswordType] = useState('password');
    var [eye, setEye] = useState('fas fa-eye');
    let navigate = useNavigate()

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function login(){
        if(email.length>0 && password.length>0){
            if(re.test(String(email).toLowerCase())){
                axios.post(`${config.baseUrl}/auth/login`, {email: email, password: password})
                .then(async function(response){
                    console.log(response);
                    if(response.data.success==true && response.data.status=='success'){
                        await Cookies.set('userCookie', response.data.jwt, { expires: 2 });
                        dispatch(setAuthenticationStatus(true))
                        await navigate("/dashboard");
                    }
                    else{
                        await dispatch(setAuthenticationStatus(false))
                        setWarning('Invalid credentials');
                        helpers.notifyToast("error", "Invalid credentials");
                    }
                })
                .catch(async function(error){
                    dispatch(setAuthenticationStatus(false))
                    console.log(error)
                    helpers.notifyToast("error", "Invalid credentials");
                })
            }
            else{
                helpers.notifyToast("error", "Invalid Email Address");
                setWarning('Invalid Email Address');
            }
        }
        else{
            helpers.notifyToast("error", "Please fill all fields");
            setWarning('Please fill all fields')
        }
    }

    function passwordd(){
        if(passwordType=='text'){
            setPasswordType('password')
            setEye('fas fa-eye-slash')
        }
        else{
            setPasswordType('text')
            setEye('fas fa-eye')
        }
    }
    return(
        <>
            <DynamicHead title="Login | Digital Ascendent" />
            <div className="login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 loginLeft" style={{background: `url('/assets/images/heroBackOld.gif')`}}>
                            {/* <img src="/assets/images/heroBack.gif" alt="" /> */}
                            <div className="container">
                                <h1>Digital Ascendent</h1>
                                <h2>
                                    <Typewriter
                                        options={{
                                            strings: ['Welcome to Digital Ascendent, where innovation meets technology.', 'Leading the way in innovative technology, Digital Ascendent is here to help you elevate your business.', 'Digital Ascendent is your partner in innovation. Elevate your business with our cutting-edge software solutions.'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 align-self-center loginRight">
                            <div>
                                <img src="/assets/images/herov3.png" alt="digital-ascendent-logo" />
                                <h1 className="mainSectionHeading">Login</h1>
                                <h5>Welcome back!</h5>
                                <div className="form">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>Email Address</h5>
                                                <input type="text" onChange={(e)=>{setEmail(e.target.value); setWarning('');}} placeholder="Your Email" />
                                            </div>
                                            <div className="col-12">
                                                <h5>Password</h5>
                                                <span className="passwordCol">
                                                    <input data-clarity-unmask="True" type={passwordType} onKeyDown={(e)=>{(e.key=='Enter') && login();}} onChange={(e)=>{setPassword(e.target.value); setWarning('');}} placeholder="Your Password" />
                                                    <span onClick={()=>{passwordd()}}><i className={eye}></i></span>
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                {/* <p>Don't have an account? <Link to='/create-account'>Create an account</Link></p> */}
                                                <p>Forgot Password? <Link to='/forgot-password'>Reset Password</Link></p>
                                                <p>{warning}</p>
                                                <button onClick={()=>{login();}}>Login</button>
                                                <Link to='/create-account'><button className="createBut">Create Account</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    );
}

export default Login;
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AllMessages from "./AllMessages";
import AnalyticsTeasers from "./analytics/teasers/AnalyticsTeasers";
import DashboardHome from "./DashboardHome";

function DashboardContent(){
    return(
        <div className="dashboardContent">
            <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                    <AnalyticsTeasers />
                </div>
            </div>
        </div>
    )
}

export default DashboardContent;
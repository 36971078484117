const items = [
    {
        icon: 'fas fa-tachometer-alt',
        name: 'Dashboard',
        link: '/dashboard'
    },
    {
        icon: 'fas fa-comment-alt',
        name: 'Messages',
        link: '/messages'
    },
    {
        icon: 'fas fa-chart-bar',
        name: 'Analytics',
        link: '/analytics'
    },
    {
        icon: 'fas fa-newspaper',
        name: 'Create Blog',
        link: '/create-blog'
    }
]

export default items;
import axios from "axios";
import { useEffect, useState } from "react"
import config from "../../../../config/config";
import BlogsAnalytics from "./BlogsAnalytics";

export default function BlogsAnalyticsCities(){

    let [blogs, setBlogs] = useState([]);
    let [blogAnayltics, setBlogAnayltics] = useState([]);

    useEffect(()=>{
        axios.get(`${config.baseUrl}/blog/view/analytics/weekly`)
        .then(response=>{
            if(response.status==200){
                setBlogs(response.data.data);
            }
        })
        .catch(e=>{
            console.log(e)
        })
    }, [])

    useEffect(()=>{
        reWriteData(blogs)
    }, [blogs])

    async function reWriteData(blogData){
        
        let countries =  [];
        let cViews =  [];
        let data = []
        await blogData.forEach((b)=>{
            if(b.city.length>0){
                if(!countries.includes(b.city)){
                    countries.push(b.city);
                    cViews.push(1);
                }
                else{
                    countries.forEach((countr, index)=>{
                        if(countr==b.city){
                            cViews[index] = parseInt(cViews[index])+1
                        }
                    })
                }
            }
        })
        await countries.forEach((cc, index)=>{
            data.push({
                city: cc,
                views: cViews[index]
            })
        })
        await setBlogAnayltics(data)
    }

    return(
        <div className="blogsAnalytics singleAnalyticsTeaser blogAnalyticsCities">
            <div className="teaser-card">
                <div className="header">
                    <h6>View on Blogs this week</h6>
                </div>
                <div className="body">
                    <h2>{blogAnayltics.length}</h2>
                    <h6>Top Cities</h6>
                    <div className="separator"></div>
                    <div className="viewsList">
                        {
                            blogAnayltics.map(dd=>{
                                return(
                                    <div className="row">
                                        <div className="col-6">
                                            <p>{dd.city}</p>
                                        </div>
                                        <div className="col-6" style={{textAlign: 'right'}}>
                                            <p>{dd.views}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}